! function(a) {
  var b = {
      common: {
        init: function() {},
        finalize: function() {}
      },
      home: {
        init: function() {},
        finalize: function() {}
      },
      about_us: {
        init: function() {}
      }
    },
    c = {
      fire: function(a, c, d) {
        var e, f = b;
        c = void 0 === c ? "init" : c, e = "" !== a, e = e && f[a], e = e && "function" == typeof f[a][c], e && f[a][c](d)
      },
      loadEvents: function() {
        a(".home-carousel").owlCarousel({
          loop: !0,
          responsiveClass: !0,
          autoplay: !0,
          autoplaySpeed: 1e3,
          nav: !1,
          navText: ["", ""],
          dots: !0,
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 1
            },
            1e3: {
              items: 1
            }
          }
        }), a("#acties-carousel").owlCarousel({
          loop: !0,
          responsiveClass: !0,
          autoplay: !0,
          autoplaySpeed: 1e3,
          nav: !1,
          navText: ["&#8592;", "&#8594;"],
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 1
            },
            1e3: {
              items: 1
            }
          }
        });
        var b = 1;
        a(".owl-dot span").each(function() {
          a(this).html(b), b++
        }), a("#menutrigger").click(function() {
          a(".menu-menu-1-container").slideToggle()
        }), a(".mobile-submenu h3").click(function() {
          a(".mobile-submenu").toggleClass("active"), a(".mobile-submenu .links").slideToggle()
        }), a("#pagesize-select").on("change", function() {
          var b = a(this).val();
          return b && (window.location = document.URL + "&pagesize=" + b), !1
        }), a("#sorting-select").on("change", function() {
          var b = a(this).val();
          return b && (window.location = document.URL + "&sorting=" + b), !1
        }), c.fire("common"), a.each(document.body.className.replace(/-/g, "_").split(/\s+/), function(a, b) {
          c.fire(b), c.fire(b, "finalize")
        }), c.fire("common", "finalize"), a.ajax({
          url: "http://lengotruckparts.com/parts-export.json",
          dataType: "json",
          success: function(b) {
            var c = a.map(b, function(a) {
              return {
                label: a
              }
            });
            a(".autocomplete").autocomplete({
              delay: 0,
              source: function(b, d) {
                var e = a.ui.autocomplete.filter(c, b.term);
                d(e.slice(0, 25))
              },
              minlength: 1
            })
          }
        })
      }
    };
  a(document).ready(c.loadEvents)
}(jQuery);
